/*
 * @Author: your name
 * @Date: 2024-10-25 17:07:15
 * @LastEditors: Chen
 * @LastEditTime: 2024-11-13 15:23:24
 * @Description: In User Settings Edit
 * @FilePath: \smurfs-react\src\skin\moduleSkin\components\inspection\index.tsx
 */
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"

import { headActionCreators } from "../../../../store/head"
import CloseButton from "../general/colseButton"

import "./index.scss"
import { Table } from "antd"
import { MinorSkinList } from "../../../data"

/**
 * @description             巡检记录
 */

const Inspection = () => {
	//两侧内容显示状态
	const drawerVisible = useSelector((value: any) => value.getIn(["home", "drawerVisible"]))
	const suffixKey = useSelector((value: any) =>
		value.getIn(["header", "suffixKey"])
	  );

	const dispatch = useDispatch()

	//巡检列表
	const [dataSource, setDataSource] = useState([])
	const [title , setTitle] = useState('秀屿区')

	const [tableHeight, setHeight] = useState(100)

	const getHeight = () => {
		const height = window.innerHeight * 0.6 - 120

		setHeight(height)
	}

	const inspectionColumns: any = [
		{
			title: "项目",
			dataIndex: "area_name",
			render: (value, record, index) => {
				const obj = {
					children: value,
					props: {
						rowSpan: 0
					}
				}
				if (index === 0 || index % 9 === 0) {
					obj.props.rowSpan = 5
				} else if (index % 9 ===  5) {
					obj.props.rowSpan = 4
				}

				return obj
			}
		},
		{
			title: "运维内容",
			dataIndex: "content"
		},

		{
			title: "上月累计",
			dataIndex: "lastMonth",
			render: (_: any, record: any) => (
				<div>
					{record.lastMonth}{" "}
					{record.content === "管网维护" ? "Km" : record.content === "绿植养护" || record.content === "垃圾清理" ? "kg" : "次"}
				</div>
			)
		},
		{
			title: "本月发生",
			dataIndex: "thisMonth",
			render: (_: any, record: any) => (
				<div>
					{record.thisMonth}{" "}
					{record.content === "管网维护" ? "Km" : record.content === "绿植养护" || record.content === "垃圾清理" ? "kg" : "次"}
				</div>
			)
		},
		
	]

	const getData = async () => {
		//获取巡检统计
		// console.log(suffixKey , MinorSkinList ,'suffixKey');
		const code = MinorSkinList[suffixKey] ? MinorSkinList[suffixKey] : MinorSkinList[0] 
		console.log(code);
		setTitle(code.title)
		
		axios.get("/app/forward/static/wd/static", {params:{area_code:code.code}}).then((res: any) => {
			if (res.code === 200) {
				// setStation(res.data);
				console.log(res, "巡检")
				const data = res?.data?.data || []
				const list = []
				 data.forEach((item) => {
					const itemList1 = [
						{
							area_name: item.area_name,
							content: "绿植养护",
							thisMonth: item.lvyh_num,
							lastMonth: item.lvyh_lmonth_num
						},
						{
							area_name: item.area_name,
							content: "垃圾清理",
							thisMonth: item.ljql_num,
							lastMonth: item.ljql_lmonth_num
						},
						{
							area_name: item.area_name,
							content: "水域巡查",
							thisMonth: item.syxj_num,
							lastMonth: item.syxj_lmonth_num
						},
						{
							area_name: item.area_name,
							content: "曝气机维护",
							thisMonth: item.bqj_num,
							lastMonth: item.bqj_lmonth_num
						},
						{
							area_name: item.area_name,
							content: "浮岛维护",
							thisMonth: item.fdwh_num,
							lastMonth: item.fdwh_lmonth_num
						},
					]

					const villageData = [
						{
							area_name: item.area_name + "-村污",
							content: "场站维护",
							thisMonth: item.cz_num,
							lastMonth: item.cz_lmonth_num,
						},
						{
							area_name: item.area_name + "-村污",
							content: "泵站维护",
							thisMonth: item.bz_num,
							lastMonth: item.bz_lmonth_num,
						},
						{
							area_name: item.area_name + "-村污",
							content: "管网维护",
							thisMonth: item.gw_num,
							lastMonth: item.gw_lmonth_num,
						},
						{
							area_name: item.area_name + "-村污",
							content: "负压系统维护",
							thisMonth: item.fy_num,
							lastMonth: item.fy_lmonth_num,
						},
					]
					list.push(...itemList1, ...villageData)
				})

				setDataSource([...list])
			}
		})
	}

	useEffect(() => {
		getData()
	}, [suffixKey])

	useEffect(() => {
		getHeight()
		
	}, [])
	return (
		<>
			{drawerVisible && (
				<div className="statistical_box">
					<div className="statistical_head">
						<div className="head_left">巡检记录 - {title}</div>
						<div className="head_right">
							<CloseButton
								onClick={() => {
									dispatch(headActionCreators.setModuleKey("0"))
								}}
							/>
						</div>
					</div>
					<div className="inspection_table">
						<Table
							columns={inspectionColumns}
							dataSource={dataSource}
							scroll={{ y: `calc(${tableHeight}px)` }}
							pagination={false}
						/>
					</div>
				</div>
			)}
		</>
	)
}

export default Inspection
