import * as constants from "./constants";

export const setSuffixKey = (data) => ({
  type: constants.setSuffixKey,
  data,
});

export const setDrawerKey = (data) => ({
  type: constants.setDrawerKey,
  data,
});

export const setModuleKey = (data) => ({
  type: constants.setModuleKey,
  data,
});
export const setAreaName = (data) => ({
  type: constants.setAreaName,
  data,
});

export const setMultipleData = (data) => ({
  type: constants.setMultipleData,
  data,
});

export const setVillageSuffixKey = (data) => ({
  type: constants.setVillageSuffixKey,
  data,
});
