import { useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";
import { PtContentDisplay, PtTableLine } from "../../../../components";
import { RecordContentBox } from "../../../components/components";
import StationsTable from "./stationsTable";

const isDev = process.env.NODE_ENV === "development";

/**
 * @description           场站实时监测
 */

const StationsMonitoring = ({ data, renewState, openKey, changeData,  setOpenKey }) => {
  const [list, setList] = useState([]); //设备监测列表
  // const [specialList, setSpecialList] = useState([]);
  const [recordList1, setRecordList1] = useState([]); //未读列表
  const [recordList2, setRecordList2] = useState([]); //已读列表
  const [recordKey, setRecordKey] = useState(0); //报警记录展示内容
  const [subOpenKey, setSubOpenKey] = useState(0)
  useEffect(() => {
    getRecordList();    
    let webSocketUrl = `${process.env.REACT_APP_WSS_API}/msg/${data.code}`;
    let ws = new WebSocket(webSocketUrl);

    ws.onopen = (e) => {
      console.log("连接成功");
    };

    ws.onmessage = (e) => {
      const newData = JSON.parse(e.data);   
      let curList = []
      newData.forEach((device: any ,index:number) =>{
        const obj = {
          devName: `${device.device}(${device.code})`,
		  code: device.code,
          groupList : [],
          devState: device.state === 1 ? "box_success" : (device.state === 4 ? "box_warning" : "box_error"),
		  state: device.state === 1 ? "正常" : (device.state === 4 ? "离线" : device.state === 0 ? '未安装' : "异常")
        }

        setOpenKey(key =>{
          return key === null && index === 0 ? device.device : key
        })
        device.groupList.forEach(deviceData => {
            const titleObject = {
              devName: deviceData.showType === 1 ? deviceData.name : " ",
              list:[]
            }

            if (deviceData.showType === 1) {
              deviceData.dataList.forEach((device) => {
                titleObject.list.push({
                  devName: device.devName,
                  display: device.display,
                  devValue: "",
                  singleVale: device.devValue,
                  devUnit:device.unit,
                })
              })
            } else {
              titleObject.list.push({
                devName:"",
                display: 1,
                devValue: "出水",
                singleVale: "进水",
              })
              const result = {}
              deviceData.dataList.forEach((device) => {
                const [name, type] = device.devName.split("-")
                if (!result[name]) {
                  result[name] = {
                    devName: name,
                    display: device.display,
                    devUnit:device.unit,
                    devValue: null, // 对应出水的isDeal
                    singleVale: null // 对应进水的isDeal
                  }
                }
                result[name][type === "进水" ? "singleVale" : "devValue"] = device.devValue || null
              })
              titleObject.list = titleObject.list.concat(Object.values(result))
            }
            obj.groupList.push(titleObject)

        })
        curList.push(obj)
      })
      setList(curList);
	  changeData(curList)
      
    };

    return () => {
      ws.close();
      setList([]);
    };
  }, [data.code]);

  /**
   * @description     获取报警记录列表
   */

  const getRecordList = () => {
    axios
      .get("/device_alarm_record/query_all", {
        params: {
          code: data.code,
          state: 0,
        },
      })
      .then((res: any) => {
        if (res.code === 200) setRecordList1(res.data);
      });
    axios
      .get("/device_alarm_record/query_all", {
        params: {
          code: data.code,
          state: 1,
        },
      })
      .then((res: any) => {
        if (res.code === 200) setRecordList2(res.data);
      });
  };

  /**
   * @description     改变报警记录状态
   * @param   id
   */

  const changeState = (id) => {
    axios
      .get("/device_alarm_record/read_msg", { params: { id } })
      .then((res: any) => {
        if (res.code === 200) {
          message.success(res.data);
          getRecordList();
          renewState();
        }
      });
  };
  return (
		<div>
			{list.map((item) => {
				return (
					<PtContentDisplay
						key={item.devName}
						title={item.devName}
						subTitle={item.state}
						subTitleClass={item.devState}
						visible={Boolean(item.devName === openKey)}
						onClick={() => {
							setOpenKey((cur) => {
								return cur === item.devName ? "" : item.devName
							})
						}}
					>
						{item.groupList.length === 1
							? item.groupList[0].list.map((it, i) => {
									return (
										Boolean(it.display) && (
											<PtTableLine
												key={i}
												leftText={it.devName}
												boxStyle={{ marginTop: it.isTitle ? "4px" : "0" }}
												rightComponent={
													<div className={"stations-right"}>
														{it.devValue ? (
															<>
																<div className={"right_content"}>{it.devValue}</div>
																<div className={"right_unit"}>
																	{it.singleVale}
																	<span>{it.devUnit ? it.devUnit : ""}</span>
																</div>
															</>
														) : (
															<div className={"right_unit"}>
																{it.singleVale || "-"}
																{it.singleVale && (
																	<span>{it.devUnit ? it.devUnit : ""}</span>
																)}
															</div>
														)}
													</div>
												}
											/>
										)
									)
							  })
							: item.groupList.map((group: any , groupIndex: number) => {
									return <div className="sub_content_title">
                    <PtContentDisplay
										key={group.devName}
										title={group.devName}
										visible={Boolean(groupIndex === subOpenKey)}
										onClick={() => {
											setSubOpenKey(cur =>{
											  return cur === groupIndex ? null : groupIndex
											})
										}}
									>
										{group.list.map((it, i) => {
											return (
												(
													<PtTableLine
														key={i}
														leftText={it.devName}
														boxStyle={{ marginTop: it.isTitle ? "4px" : "0" }}
														rightComponent={
															<div className={"stations-right"}>
																{it.devValue ? (
																	<>
																		<div className={"right_content"}>
																			{it.devValue}
																		</div>
																		<div className={"right_unit"}>
																			{it.singleVale}
																			<span>{it.devUnit ? it.devUnit : ""}</span>
																		</div>
																	</>
																) : (
																	<div className={"right_unit"}>
																		{it.singleVale || "-"}
																		{it.singleVale && (
																			<span>{it.devUnit ? it.devUnit : ""}</span>
																		)}
																	</div>
																)}
															</div>
														}
													/>
												)
											)
										})}
									</PtContentDisplay>
                  </div>
							  })}
					</PtContentDisplay>
				)
			})}

			<PtContentDisplay
				title={"报警记录"}
				visible={Boolean(openKey === "报警记录")}
				onClick={() => {
					setOpenKey((cur) => {
						return cur === "报警记录" ? "" : "报警记录"
					})
				}}
			>
				<div className={"record__head"}>
					<div>
						<div
							className={`record__tag ${!Boolean(recordKey) && "record__active__tag"}`}
							onClick={() => setRecordKey(0)}
						>
							未读 ({recordList1.length})
						</div>
						<div
							className={`record__tag ${Boolean(recordKey) && "record__active__tag"}`}
							onClick={() => setRecordKey(1)}
						>
							已读 ({recordList2.length})
						</div>
					</div>
				</div>
				<div className={"record__content"}>
					{(!Boolean(recordKey) ? recordList1 : recordList2).map((item, i) => {
						return <RecordContentBox key={i} data={item} changeState={(value) => changeState(value)} />
					})}
				</div>
			</PtContentDisplay>
		</div>
	)
};

export default StationsMonitoring;
