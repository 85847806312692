import React, { Component } from "react";
import { PtDrawer, PtCard } from "../../components";
import { connect, useSelector } from "react-redux";
import {
  PtVillageWaterPurification,
  PtRealTimeEvent,
  PtOperationStatus,
  PtMinorOperatingIndex,
  PtCondition,
} from "../components/components";
import {
  WaterData,
  progressbarData,
  wsVillageList,
  circleData,
  progressbarData2,
  minorOperatingIndexData,
} from "../../utils/strConfig";
import "./index.scss";
import { MinorSkinList } from "../data";
import axios from "axios";

interface Props {
  headSuffixKey: number;
}

interface State {
  operaStatusList: any[];
  waterData: any[];
  timelyrate: number
}

class MinorSkin extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      operaStatusList: [],
      waterData: [],
      timelyrate: 0
    };
  }
  componentDidMount() {
    this.getWaterData();
    this.getRankData();
    const operaStatusList = wsVillageList.map((it) => {
      return { title: it, ...circleData };
    });
    this.setState({
      operaStatusList,
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.headSuffixKey !== this.props.headSuffixKey) {
      this.getWaterData();
      this.getRankData();
      
    }
  }

  /**
   * @description               获取污净水数据
   */

  getWaterData = () => {
    const { headSuffixKey } = this.props;
    axios
      .get(`/device/get_num_by_area_code/${MinorSkinList[headSuffixKey].code}`)
      .then((res: any) => {
        if (res.code === 200) {
          this.setState({
            waterData: res.data,
          });
        }
      });
  };

    /**
   * @description               获取运营情况
   */
  getRankData = () => {
    const { headSuffixKey } = this.props;
    
    const date = new Date();

    axios
    .get("/app/forward/static/device/event_static/village", {
      params: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        // day: date.getDate() - 1,
        area_code: MinorSkinList[headSuffixKey].code,
        get_all: "1",
      },
    })
    .then((res: any) => {
      // console.log(res , '事件处置');
      if (res.code === 200 && res.data.success) {
        const list = res.data.data.map(area => ({
          title: area.area_name,
          total: area.done_num + area.wait_deal_num  + area.dealing_num, // 假设 deal_pat 代表该地区的总处理量
          data: [
              { type: "完成", value: "complete", number: area.done_num, color: "#00FFCB" },
              { type: "告警", value: "warn", number: area.wait_deal_num, color: "#E34438" },
              { type: "处理", value: "handle", number: area.dealing_num, color: "#FFC96F" }
          ]
        }));

        const areas = res.data.data;

        // 初始化总数计数器
        let totalDone = 0;
        let totalDealing = 0;
        let totalWaitDeal = 0;

        // 累加所有区域的数值
        areas.forEach(area => {
            totalDone += area.done_num;
            totalDealing += area.dealing_num;
            totalWaitDeal += area.wait_deal_num;
        });

        // 计算总的处理率
        const totalTransactions = totalDone + totalWaitDeal + totalDealing;
        const completedTransactions = totalDone + totalDealing;
        let overallProcessRate = 0;

        if (totalTransactions > 0) {
            overallProcessRate = (completedTransactions / totalTransactions) * 100; // 转换为百分比
        }

        console.log(overallProcessRate , 'overallProcessRate');
        
        
        // setOperaList(list);
        this.setState({
          operaStatusList: list,
          timelyrate:overallProcessRate
        });
      }
      
    });
  }

  render() {
    const { headSuffixKey } = this.props;
    const { operaStatusList, waterData , timelyrate } = this.state;
    return (
      <PtDrawer
        visible={true}
        left={
          <>
            <PtVillageWaterPurification
              title={`${MinorSkinList[headSuffixKey].title}污净水`}
              enTitle={"Village sewage purification"}
              staticTypeNumber={4}
              data={[...WaterData, ...waterData]}
              percentList={
                waterData.length > 0 ? waterData.map((it) => it.rate * 100) : []
              }
            />
            <PtMinorOperatingIndex data={minorOperatingIndexData} />
            <PtCondition timelyrate={timelyrate} operaStatusList={operaStatusList} />
          </>
        }
        right={
          <>
            <PtRealTimeEvent scrollY={185} />
            <PtOperationStatus
              timelyrate={97}
              progressbarData={progressbarData2}
            />
          </>
        }
      />
    );
  }
}

const mapState = (state) => ({
  headSuffixKey: state.getIn(["header", "suffixKey"]),
});

export default connect(mapState)(MinorSkin);
