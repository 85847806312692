/*
 * @Author: your name
 * @Date: 2024-10-14 17:55:42
 * @LastEditors: Chen
 * @LastEditTime: 2024-11-10 15:50:02
 * @Description: In User Settings Edit
 * @FilePath: \smurfs-react\src\components\simple\index.tsx
 */
import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { Popover, Drawer, Divider, DatePicker, Input, Checkbox } from "antd";
import axios from "axios";
import JingGai from "../../assets/icon/jinggai.jpg";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { homeActionCreators } from "../../store/home";
import ThreeModel from "../threeModel";
import { headActionCreators } from "../../store/head";
interface PtIconProps {
  type?: string;
  src?: any; // svg
  className?: string;
  title?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const PtIcon = forwardRef(
  (
    { type, src, className, children, ...rest }: PtIconProps,
    ref: (instance) => void
  ) => {
    const [svg, setSvg] = useState(undefined);
    useEffect(() => {
      if (!type && src) {
        fetch(src)
          .then((r) => r.text())
          .then((r) => {
            setSvg(r);
          });
      }
    }, []);
    return (
      <i
        className={`pt-icon iconfont ${type || ""} ${className}`}
        ref={ref}
        {...rest}
        {...(svg ? { dangerouslySetInnerHTML: { __html: svg } } : { children })}
      />
    );
  }
);

interface PtTitleSuffixProps {
  suffixKey: number;
  onMenuChange?: (value, areaName) => void;
}

export const PtTitleSuffix = ({
  suffixKey,
  onMenuChange,
}: PtTitleSuffixProps) => {
  const [selectList, setSelectList] = useState([]);
  const [selectVisible, setSelectVisible] = useState(false);
  const [selectText, setSelectText] = useState("秀屿区");
  const villageSuffixKeyTemp = useSelector((value: any) =>
    value.getIn(["header", "villageSuffixKey"])
  );
  
  const dispatch = useDispatch();

  const onChange = (data, firstName?) => {
    let text = firstName ? firstName + "-" + data.label : data.label;
    setSelectText(text);
    setSelectVisible(false);
    onMenuChange(data, text);
  };

  const onChangeMenu = (data: any) => {
    if (villageSuffixKeyTemp.includes(data)) {
      const newIndexList = villageSuffixKeyTemp.filter(v => v !== data);
      dispatch(headActionCreators.setVillageSuffixKey(newIndexList));
    } else {
      const newIndexList = villageSuffixKeyTemp.push(data);
      dispatch(headActionCreators.setVillageSuffixKey(newIndexList));
    }
  }

  const init = () => {
    axios.get(`/admin_area/getTreeById/210`).then((res: any) => {
      if (res.code === 200) {
        const newData = [
          { areaCode: "350305000000", label: "秀屿区", value: 0 },
          ...res.data,
        ];
        setSelectList(newData);
      }
    });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (suffixKey === 0) {
      setSelectText("秀屿区");
    } else {
      axios.get(`/admin_area/getInfoById/${suffixKey}`).then((res: any) => {
        if (res.code === 200) {
          setSelectText(
            suffixKey < 12
              ? res.data.name
              : `${res.data.parentName}-${res.data.name}`
          );
        }
      });
    }
  }, [suffixKey]);

  return (
    <div className={"pt__title__suffix"}>
      <div className={"pt__title__suffix--left"}>
        {suffixKey === 0 ? "主平台" : "分平台"}
      </div>
      <div className={"pt__title__suffix--right"}>
        <div
          className={"pt__title__text"}
          onClick={() => {
            setSelectVisible(!selectVisible);
          }}
        >
          <span>{selectText}</span>
          <PtIcon
            className={`icon-jiantou2  ${selectVisible && "right--active"}`}
          />
        </div>
        {selectVisible && (
          <div className={"pt__title__list"}>
            {selectList.map((r, i) => {
              return r.children && r.children.length > 0 ? (
                <Popover
                  key={i}
                  placement="rightBottom"
                  content={
                    <div className="list_children_box">
                      {r.children.map((item, index) => (
                        

                        <div
                          key={index}
                          className={`list__children ${
                            suffixKey === item.value && "list__children--active"
                          }`}
                          onClick={() => {
                            onChange(item, r.label);
                            onChangeMenu(item.value)
                          }}
                        >
                          <Checkbox
                            checked={villageSuffixKeyTemp.includes(item.value)}
                            onClick={(e) => {
                              e.stopPropagation()
                              // if(villageSuffixKeyTemp.includes(item.value)){
                              //   e.stopPropagation()
                              // }
                            }}
                            onChange={()=> {
                              onChangeMenu(item.value)
                            }}
                          >
                            {item.label}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  }
                >
                  <div
                    className={`list__text ${
                      suffixKey === r.value && "list--active"
                    }`}
                    onClick={() => {
                      onChange(r);
                    }}
                  >
                    {r.label}
                  </div>
                </Popover>
              ) : (
                <div
                  key={i}
                  className={`list__text  ${
                    suffixKey === r.value && "list--active"
                  }`}
                  onClick={() => {
                    onChange(r);
                  }}
                >
                  {r.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

interface PtMenuProps {
  onChangeDrawer: (value) => void;
  onChangeModule: (value: string) => void;
}

export const PtMenu = ({ onChangeDrawer, onChangeModule }: PtMenuProps) => {
  const routes = [
    {
      title: "应急调度",
      onClick: () => {},
      children: [
        {
          title: "应急预案",
          onClick: () => {
            onChangeModule("1-1");
          },
          children: [],
        },
        {
          title: "应急人员",
          onClick: () => {
            onChangeModule("1-2");
          },
          children: [],
        },
        {
          title: "应急记录",
          onClick: () => {
            onChangeModule("1-3");
          },
          children: [],
        },
      ],
    },
    {
      title: "统计分析",
      onClick: () => {},
      children: [
        {
          title: "统计分析",
          onClick: () => {
            onChangeModule("2-1");
          },
          children: [],
        },
        {
          title: "巡检记录",
          onClick: () => {
            onChangeModule("2-2");
          },
          children: [],
        },
      ],
    },
    {
      title: "运维管理",
      onClick: () => {},
      children: [
        {
          title: "资产管理",
          onClick: () => {},
          children: [
            {
              title: "场站",
              onClick: () => {
                onChangeDrawer(1);
              },
            },
            {
              title: "泵站",
              onClick: () => {
                onChangeDrawer(2);
              },
            },
            {
              title: "管线设施",
              onClick: () => {
                onChangeDrawer(3);
              },
            },
            {
              title: "负压",
              onClick: () => {
                onChangeDrawer(4);
              },
            },
          ],
        },
        {
          title: "运维管理",
          onClick: () => {
            onChangeModule("3-2");
          },
          children: [],
        },
        {
          title: "巡检人员",
          onClick: () => {
            onChangeModule("3-1");
          },
          children: [],
        },
      ],
    },
    {
      title: "专家库",
      onClick: () => {
        onChangeModule("4");
      },
      children: [],
    },
  ];

  console.log(routes);
  
  return (
    <div className={"pt-menu"}>
      <div className={"pt-menu__router-list"}>
        {routes.map((it, key) =>
          it.children.length > 0 ? (
            <Popover
              key={key}
              trigger={"click"}
              getPopupContainer={(triggerNode) => triggerNode}
              content={it.children.map((item, index) =>
                item.children.length > 0 ? (
                  <Popover
                    key={index}
                    getPopupContainer={(triggerNode) => triggerNode}
                    placement="leftTop"
                    content={item.children.map((rest, i) => (
                      <div key={i} onClick={rest.onClick}>
                        {rest.title}
                      </div>
                    ))}
                  >
                    <div>{item.title}</div>
                  </Popover>
                ) : (
                  <div key={index} onClick={item.onClick}>
                    {item.title}
                  </div>
                )
              )}
            >
              <div className={"router__content"}>
                <div>{it.title}</div>
              </div>
            </Popover>
          ) : (
            <div key={key} className={"router__content"} onClick={it.onClick}>
              <div>{it.title}</div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

interface PtDrawerProps {
  visible: boolean;
  left: any;
  right: any;
}

/**
 * @description 两侧抽屉样组件
 */

export const PtDrawer = ({ visible, left, right }: PtDrawerProps) => {
  return (
    <>
      <Drawer
        placement="left"
        closable={false}
        maskClosable={false}
        mask={false}
        width={448}
        getContainer={false}
        className={"pt-drawer pt-drawer--left"}
        visible={visible}
      >
        {left}
      </Drawer>
      <Drawer
        placement="right"
        closable={false}
        maskClosable={false}
        mask={false}
        width={448}
        getContainer={false}
        className={"pt-drawer pt-drawer--right"}
        visible={visible}
        style={right ? {} : { pointerEvents: "none" }}
      >
        {right}
      </Drawer>
    </>
  );
};

interface PtCardProps {
  title: string;
  entitle?: string;
  titleStyle?: any;
  suffix?: any;
  children: any;
  classN?: string;
}

export const PtCard = ({
  title,
  entitle,
  titleStyle = {},
  suffix,
  classN,
  children,
}: PtCardProps) => {
  const headClass = {
    5: "topFont5",
    6: "topFont6",
    7: "topFont7",
  };

  return (
    <div className={`${classN} pt-card`}>
      <div
        className={`pt-card__head ${
          title
            ? title.length <= 4
              ? "topFont4"
              : headClass[title.length]
            : "topFont4"
        } ${title && title.length > 7 && "topFont7"}`}
        title={title}
      >
        <div className={"pt-card__head--left"}>
          <div style={titleStyle}>{title}</div>
        </div>
        {suffix}
      </div>
      <div className={"pt-card__content"}>{children}</div>
    </div>
  );
};

export const PtCardSuffix = ({ label, value = undefined, unit = "" }) => {
  return (
    <div className={"pt-card-suffix"}>
      <span>{label}</span>
      <span className={"value"}>{value}</span>
      <span>{unit}</span>
    </div>
  );
};

interface PtSvgCircleProps {
  percentList: number[];
  active?: boolean;
  click?: boolean;
  svgSize?: number;
  circleSizeList?: number[];
  strokeList?: string[];
  strokeWidth?: number;
}

export const PtSvgCircle = ({
  active,
  svgSize = 45,
  circleSizeList = [40, 32, 24, 20],
  percentList = [20, 20],
  strokeList = ["rgba(50, 197, 255, 1)", "rgba(0, 255, 203, 1)"],
  strokeWidth = 2,
  click,
}: PtSvgCircleProps) => {
  const [newPercentList, setPercentList] = useState([]);
  const [open, setOpen] = useState(false);
  const [cxy, setCxy] = useState(0);

  useEffect(() => {
    setCxy(svgSize / 2);
    const list = percentList.map((percent, i) => {
      const pathLen = circleSizeList[i] * Math.PI; //圆的周长
      return (pathLen * percent) / 100 + "," + pathLen;
    });
    setPercentList(list);
  }, [percentList]);
  useEffect(() => {
    setOpen(active);
  }, [active]);

  // function onClick() {
  //     if (onChange) {
  //         setOpen(!open);
  //         onChange(!open)
  //     }
  // }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgSize}
      height={svgSize}
      viewBox={`0 0 ${svgSize} ${svgSize}`}
      className={"pt-svg-circle"}
    >
      {circleSizeList.map((it, i) => {
        return (
          <g key={i}>
            <circle
              stroke="#ffffff25"
              fill="none"
              className="pt-svg-circle-bg"
              strokeWidth={strokeWidth}
              cx={cxy}
              cy={cxy}
              r={it / 2}
            />
            <circle
              fill="transparent"
              className="pie-bar0"
              cx={cxy}
              cy={cxy}
              r={it / 2}
              stroke={strokeList[i]}
              strokeWidth={strokeWidth}
              style={{ strokeDasharray: newPercentList[i] }}
            />
          </g>
        );
      })}
      {open ? (
        <circle
          fill="rgba(255, 201, 111, 1)"
          strokeWidth={0}
          cx={cxy}
          cy={cxy}
          r={circleSizeList[2] / 2 - 2}
        />
      ) : null}
      {click ? (
        <text
          x={cxy}
          y={cxy}
          fill={open ? "#333" : "#fff"}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{ fontSize: 10 }}
        >
          {open ? "ON" : "OFF"}
        </text>
      ) : null}
    </svg>
  );
};

export const PtProgress = ({ text, percent }) => {
  return (
    <div className={"pt-progress"}>
      <div className={"title"}>
        <div>{text}</div>
        <div>{percent}%</div>
      </div>
      <div className={"stripes"}>
        <div
          className={"stripes-active"}
          style={{ width: `${percent}%`, backgroundColor: "#02D281" }}
        />
        <div
          style={{ width: `${100 - percent}%`, backgroundColor: "#6D7278" }}
        />
      </div>
    </div>
  );
};

export const PtTab = ({ data, value, onChange, fill = false, auto = true }) => {
  return (
    <div className={"pt-tab"}>
      {data.map((it, i) => {
        return (
          <div
            key={i}
            className={`pt-tab__item ${value === it.value ? "active" : ""} ${
              fill ? " fill" : ""
            } ${it.disabled && "disabled"}`}
            style={
              auto
                ? {
                    width: `${100 / data.length}%`,
                  }
                : {}
            }
            onClick={() => {
              !it.disabled && onChange(it.value);
            }}
          >
            {it.text || it.title}
          </div>
        );
      })}
    </div>
  );
};

export const PtSmallCircleTab = ({
  data,
  colorList = [
    "rgba(50, 197, 255, 1)",
    "rgba(233, 111, 101, 1)",
    "rgba(0, 255, 203, 1)",
  ],
  onChange,
}) => {
  const [active, setActive] = useState(undefined);

  function onClick(value) {
    setActive(value);
    onChange(value);
  }

  return (
    <div className={"pt-small-circle-tab"}>
      {data.map((it, i) => {
        return (
          <div
            key={i}
            className={"pt-small-circle"}
            onClick={() => onClick(it.value)}
          >
            <div
              className={"point"}
              style={{
                borderColor: colorList[i],
                backgroundColor:
                  active === it.value ? colorList[i] : "transparent",
              }}
            />
            <span>{it.text}</span>
          </div>
        );
      })}
    </div>
  );
};

/**
 * @description 小标题组件
 */

export const PtChildTitle = ({
  title = "",
  leftContent = null,
  rightContent = null,
}) => {
  return (
    <div className={"pt-child-title"}>
      <div className={"pt-child-title--left"}>
        {title} {leftContent}
      </div>
      <div>{rightContent}</div>
    </div>
  );
};

/**
 * @description 颜色图例组件
 */

export const PtColorLegend = ({ color, text }) => {
  return (
    <div className={"pt-color-legend"}>
      <div className={"pt-color-legend__box"} style={{ background: color }} />
      <div className={"pt-color-legend__text"}>{text}</div>
    </div>
  );
};

/**
 * @description 图标图例组件
 */

export const PtIconLegend = ({ data, fontSize = "16px", boxStyle = {} }) => {
  return (
    <div className={"pt-icon-legend"} style={boxStyle}>
      {data.map((item, i) => (
        <div key={i}>
          <span>{item.text}</span>
          <PtIcon
            className={`${item.iconName} ${item.newClass}`}
            style={{ fontSize }}
          />
        </div>
      ))}
    </div>
  );
};

/**
 * @description 显示控制下  样式组件
 */

export const PtControlCard = ({
  mainlyData,
  attachedData,
  buttonTitle,
  buttonColor,
  buttonClick,
}) => {
  return (
    <div className={"pt-control-card"}>
      <div className={"pt-control-card__content"}>
        <div className={"content__title"}>
          <span>主要设施</span>
          <div style={{ background: buttonColor }} onClick={buttonClick}>
            {buttonTitle}
          </div>
        </div>
        <div className={"content__list"}>{mainlyData}</div>
      </div>
      <div className={"pt-control-card__content"}>
        <div className={"content__title"}>
          <span>附属设施</span>
        </div>
        <div className={"content__list"}>{attachedData}</div>
      </div>
    </div>
  );
};

export const PtTag = ({ text, className = "", active, onClick, index }) => {
  const classData = {
    0: "tag--blue",
    1: "tag--cyan",
    2: "tag--yellow",
    3: "tag--green",
    4: "tag--pink",
  };
  return (
    <div
      className={`pt-tag ${className} ${active ? classData[index] : ""}`}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

/**
 * @description 设施清单的table
 */

export const PtListTable = ({ data, onClick, activeId = 0 }) => {
  const type = {
    1: "正常",
    2: "离线",
  };
  return (
    <div className={"pt-list-table"}>
      {data.map((item, i) => {
        return item.children ? (
          <div
            key={i}
            className={"pt-list-table--unfold"}
            onClick={() => onClick(item, 0)}
          >
            <div className={"unfold--left"}>
              <PtIcon className={"icon-zhankaiicon1"} />
              <span>{item.title}</span>
            </div>
            <div className={"unfold--right"}>({item.number})</div>
          </div>
        ) : (
          <div
            key={i}
            className={`pt-list-table--text ${
              item.id === activeId
                ? item.type === 1
                  ? "active--text--normal"
                  : "active--text--abnormal"
                : ""
            }`}
            onClick={() => onClick(item, 1)}
          >
            <span>{item.title}</span>
            <div className={`${item.type === 1 ? "normal" : "abnormal"}`}>
              {type[item.type]}
            </div>
          </div>
        );
      })}
    </div>
  );
};

/**
 * @description 基本信息内容
 */

export const PtBasicInformation = ({ data, type }) => {
  const [buttonState, setButtonState] = useState(false);
  const [basicData, setBasicData] = useState<any>({});
  const [wellData, setWellData] = useState<any>({});

  const flowChartData = useSelector((value: any) =>
    value.getIn(["home", "flowChartData"])
  );

  const monitorShow = useSelector((value: any) =>
    value.getIn(["home", "monitorShow"])
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (type !== 0) {
      axios.get(`/device_info/query_info_by_id/${data.id}`).then((res: any) => {
        if (res.code === 200) {
          setBasicData(res.data);
        }
      });
      if (flowChartData.visible) {
        dispatch(
          homeActionCreators.setFlowChartData({
            visible: true,
            activeData: data,
          })
        );
      }
      if (monitorShow.visible) {
        if (window["EZOPENDemo"]) {
          window["EZOPENDemo"].stop();
        }
        dispatch(
          homeActionCreators.setMonitorShow({
            visible: false,
            activeId: null,
          })
        );
      }
    } else {
      axios.get(`/well/queryInfoById/${data.id}`).then((res: any) => {
        if (res.code === 200) setWellData(res.data);
      });
    }

    return () => {
      setBasicData({});
      setWellData({});
      dispatch(
        homeActionCreators.setFlowChartData({
          visible: false,
          activeData: {},
        })
      );
    };
  }, [data.id, type]);
  return (
    <div className={"pt-basic-information"}>
      {type !== 0 && (
        <PtTableLine
          leftText={"地址详情"}
          rightText={basicData.areaDetails ? basicData.areaDetails : ""}
        />
      )}
      <PtTableLine
        leftText={"所属区域"}
        rightText={
          type !== 0
            ? basicData.areaName
              ? basicData.areaName
              : ""
            : wellData.areaName
            ? wellData.areaName
            : ""
        }
      />
      {type === 0 && (
        <>
          <PtTableLine
            leftText={"坐标"}
            rightText={wellData.geoPoint ? wellData.geoPoint.toString() : ""}
          />
          <PtTableLine
            leftText={"高程"}
            rightText={wellData.height ? `${wellData.height}m` : ""}
          />
          {wellData.attributeList &&
            wellData.attributeList.map((item, index) => {
              return (
                item.pipeAttributeItemName !== "无" && (
                  <PtTableLine
                    key={index}
                    leftText={item.pipeAttributeName}
                    rightText={item.pipeAttributeItemName}
                  />
                )
              );
            })}
        </>
      )}
      {type !== 0 && (
        <>
          {basicData.componentCodes && typeof basicData.componentCodes === 'object' &&
            Object.keys(basicData.componentCodes).map((code) => (
              <PtTableLine key={code} leftText={code === '设备编号' ? code : `设备编号（${code}）`} rightText={basicData.componentCodes[code]} />
            ))
          }
          {type !== 1 && (
            <>
              <PtTableLine
                leftText={"规格说明"}
                rightText={basicData.spec ? basicData.spec : ""}
              />
              <PtTableLine
                leftText={"运行模式"}
                rightText={basicData.runMode ? basicData.runMode : ""}
              />
            </>
          )}
          <PtTableLine
            leftText={"备注"}
            rightText={basicData.remark ? basicData.remark : ""}
          />
        </>
      )}
      <PtTableLine
        leftText={"技术文档"}
        rightComponent={
          <div className={"document__box"}>
            <div className={"box--left"}>
              {data.docs &&
                data.docs.map((item, i) => (
                  <PtIcon key={i} className={"icon-wenjianicon"} />
                ))}
            </div>
            <Divider type="vertical" />
            <PtIcon className={"icon-xiazaiicon1"} />
          </div>
        }
      />
      <PtImageTableLine
        data={type === 0 ? [{ url: JingGai }] : [{ url: basicData.photos }]}
      />
      {type === 1 && (
        <PtTableLine
          leftText={"现场监控"}
          rightComponent={
            <div className="monitoring">
              <div className="monitoring_number">
                {basicData.monitorNum ? basicData.monitorNum : 0}台
              </div>
              <div
                className="monitoring_show"
                onClick={() => {
                  dispatch(
                    homeActionCreators.setMonitorShow({
                      visible: true,
                      activeId: basicData.id,
                    })
                  );
                }}
              >
                展示
              </div>
            </div>
          }
        />
      )}
      <PtTableLine
        leftText={"维养单位"}
        rightText={
          type !== 0
            ? basicData.maintenanceUnit
            : "莆田市秀屿区上航水环境投资有限公司"
        }
      />
      <PtTableLine
        leftText={"责任人"}
        rightText={type !== 0 ? basicData.principal : "潘幸"}
      />
      <PtTableLine
        leftText={"联系方式"}
        rightText={type !== 0 ? basicData.concatWay : "13850216827"}
      />
      <PtTableLine
        leftText={"建设单位"}
        rightText={
          type !== 0
            ? basicData.constructionUnit
            : "莆田市秀屿区上航水环境投资有限公司"
        }
      />
      <PtTableLine
        leftText={"竣工时间"}
        rightText={type === 1 ? basicData.completionTime : "2020年"}
      />
      {type === 1 && (
        <PtTableLine
          leftText={"工作流程图"}
          rightComponent={
            <div
              className="show_image_box"
              onClick={() => {
                dispatch(
                  homeActionCreators.setFlowChartData({
                    visible: true,
                    activeData: data,
                  })
                );
              }}
            >
              <ThreeModel data={data} />
            </div>
          }
          topStyle={{
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        />
      )}
      <div className={`assign-button ${buttonState && ""}`} onClick={() => {}}>
        指派巡检
      </div>
    </div>
  );
};

/**
 * @description 维护记录
 */

export const PtMaintainRecords = ({ data }) => {
  const [list, setList] = useState([]);
  useEffect(() => {}, []);
  return (
    <div className={"pt-maintain-records"}>
      <PtTableLine
        leftComponent={
          <div className={"date-picker"}>
            <DatePicker picker="month" bordered={false} size={"small"} />
          </div>
        }
        rightComponent={
          <PtIcon
            className={"icon-xiazaiicon1"}
            style={{ color: "#0091FF", cursor: "pointer" }}
          />
        }
        boxStyle={{
          padding: "0 4px",
        }}
      />
      <div className={"pt-maintain-records__list"}>
        {list.map((item, i) => (
          <PtMaintainTable key={i} data={item} />
        ))}
      </div>
    </div>
  );
};

/**
 * @description 维护列表样式组件
 */

export const PtMaintainTable = ({ data }) => {
  return (
    <div className={"pt-maintain-table"}>
      <PtTableLine leftText={"维护时间"} rightText={""} />
      <PtTextTableLine leftText={"维护日志"} rightText={""} />
      <PtTableLine leftText={"责 任 人"} rightText={""} />
      <PtTableLine leftText={"联系电话"} rightText={""} />
      <PtImageTableLine data={[]} />
    </div>
  );
};

/**
 * @description 通用列表内容
 */

interface PtTableLineProps {
  leftText?: string;
  rightText?: string;
  leftComponent?: ReactNode;
  rightComponent?: ReactNode;
  boxStyle?: any;
  bottomComponent?: ReactNode;
  topStyle?: any;
}

export const PtTableLine = ({
  leftText,
  leftComponent,
  rightText,
  rightComponent,
  boxStyle,
  bottomComponent = null,
  topStyle = {},
}: PtTableLineProps) => {
  return (
    <div className={"pt-table__line"} style={boxStyle}>
      <div className={"line--top"} style={topStyle}>
        {leftComponent ? (
          leftComponent
        ) : (
          <div className={"line--left"}>{leftText}</div>
        )}
        {rightComponent ? (
          rightComponent
        ) : (
          <div className={"line--right"}>{rightText ? rightText : "-"}</div>
        )}
      </div>
      {bottomComponent && (
        <div className={"line--bottom"}>{bottomComponent}</div>
      )}
    </div>
  );
};

const PtTextTableLine = ({ leftText, rightText }) => {
  return (
    <div className={"pt-table__line text__line"}>
      <div className={"line--left"}>{leftText}</div>
      <div className={"line--right__text"}>{rightText}</div>
    </div>
  );
};

/**
 * @description 现场照片样式组件
 */

const PtImageTableLine = ({ data }) => {
  return (
    <div
      className={"pt-table__line"}
      style={{
        flexDirection: "column",
      }}
    >
      <div className={"line__head"}>
        <div>现场照片</div>
        <div>{data.length}张</div>
      </div>
      {data.length > 0 && <PtImgCarousel data={data} />}
    </div>
  );
};

/**
 * @description 图片走马灯
 */

export const PtImgCarousel = ({ data, onClick = (value) => {} }) => {
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(1);
  const ref: any = useRef();
  useEffect(() => {
    const newTotal = Math.ceil(data.length / 2);
    setTotal(newTotal);
  }, []);
  const changePage = (key) => {
    const newCurrent = key === 1 ? current - 1 : current + 1;
    setCurrent(newCurrent);
    ref.current.style.left =
      newCurrent === 1 ? "0" : `-${(newCurrent - 1) * 360 + 4}px`;
  };
  return (
    <div className={"pt-img-carousel"}>
      <PtIcon
        className={`icon-jiantouicon left__button ${
          current === 1 && "unActive"
        }`}
        onClick={() => {
          if (current > 1) {
            changePage(1);
          }
        }}
      />
      <PtIcon
        className={`icon-jiantouicon right-button ${
          current === total && "unActive"
        }`}
        onClick={() => {
          if (current !== total) {
            changePage(2);
          }
        }}
      />
      <div
        className={"pt-img-carousel__content"}
        ref={ref}
        style={{
          width: `${360 * total}px`,
        }}
      >
        {data.map((item, i) => {
          return (
            <div key={i} onClick={() => onClick(item.url)}>
              <img src={item.url} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

/**
 * @description 列表单选
 */

export const PtCheckList = ({ data, activeData, setActiveData }) => {
  return (
    <div className={"pt-check-list"}>
      {data.map((item, i) => (
        <div
          key={i}
          className={`${item.id === activeData.id && item.ActiveClass}`}
          onClick={() => {
            setActiveData(item);
          }}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

/**
 * @description 场站与泵站的显示控制下的按钮组
 */

export const PtControlButtonList = ({ data, activeData, onClick }) => {
  return (
    <div className={"pt-control-button-list"}>
      {data.map((item, i) => (
        <div
          key={i}
          style={
            activeData.includes(i)
              ? {
                  background: item.color,
                  color: "#000",
                }
              : {}
          }
          onClick={() => {
            onClick(i);
          }}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

/**
 * @description 设施清单下的搜索框
 */

export const PtSearch = ({ onChange }) => {
  return (
    <div className={"pt-search"}>
      <Input
        prefix={<PtIcon className={"icon-chazhaoicon"} />}
        size={"small"}
        bordered={false}
        placeholder={"输入关键字查找"}
        onPressEnter={(e: any) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

export const PtContentDisplay = ({ title, visible , children, onClick , subTitle = '' , subTitleClass = ''}) => {
  return (
    <div className={"pt-content-display"}>
      <div className={"content__head"} onClick={onClick}>
        <span>{title}</span>
        <span>
          <span className={subTitleClass}>{subTitle}</span>
          <PtIcon
            style={{marginLeft: '10px'}}
            className={`${visible ? "icon-shousuoicon" : "icon-zhankaiicon"}`}
          />
        </span>
        
      </div>
      <div className={`${visible ? "content--show" : "content--hide"}`}>
        {children}
      </div>
    </div>
  );
};

export const PtContentProgress = ({ data, title = "", color = "" }) => {
  const [percentage, setPercentage] = useState(0);
  const [typeTitle, setTypeTitle] = useState("");
  const [typeColor, setTypeColor] = useState("");

  useEffect(() => {
    if (typeof data === "number") {
      setPercentage(data);
    } else {
      if (data.have) {
        setPercentage((data.have / data.total) * 100);
      } else {
        setPercentage((data.data / data.total) * 100);
      }
    }
  }, [data]);
  useEffect(() => {
    const a = 100 / 6;
    const newA = Number(a.toFixed(2));
    if (percentage <= newA) {
      setTypeTitle("I类");
      setTypeColor("#88b6f1");
    } else if (percentage <= newA * 2) {
      setTypeTitle("II类");
      setTypeColor("#0091FF");
    } else if (percentage <= newA * 3) {
      setTypeTitle("III类");
      setTypeColor("#00FFCB");
    } else if (percentage <= newA * 4) {
      setTypeTitle("IV类");
      setTypeColor("#02D281");
    } else if (percentage <= newA * 5) {
      setTypeTitle("V类");
      setTypeColor("#FFC96F");
    } else {
      setTypeTitle("劣V类");
      setTypeColor("#E96F65");
    }
  }, [percentage]);

  return (
    <div className={"pt-content-progress"}>
      <div className={"progress__head"}>
        <div className={"head--left"}>{data.title || data.code}</div>
        <div
          className={"head--right"}
          style={{
            color: color ? color : typeColor,
          }}
        >
          {title ? title : typeTitle}
        </div>
      </div>
      <div className={"progress__box"}>
        <div
          style={{
            background: color ? color : typeColor,
            width: `${percentage}%`,
          }}
        ></div>
      </div>
    </div>
  );
};
