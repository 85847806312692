import React, { useEffect, useState } from "react";
import { Select, Tag, Table, message, DatePicker } from "antd";
import {
  PtCard,
  PtSvgCircle,
  PtIcon,
  PtProgress,
  PtTab,
  PtSmallCircleTab,
  PtContentDisplay,
  PtTableLine,
} from "../../components";
import smallCircle from "../../assets/icon/point_circle32.svg";
import pointCircle from "../../assets/icon/point_circle68.svg";
import waterImg from "../../assets/icon/water.svg";
import "./components.scss";
import axios from "axios";
import { Chart, Interval, Tooltip } from "bizcharts";
import StationsTable from "../assetsSkin/components/stations/stationsTable";
import { useSelector } from "react-redux";
import { MinorSkinList } from "../data";
import moment from "moment";

const { Option } = Select;
const isDev = process.env.NODE_ENV === "development";

export const PtVillageWaterPurification = ({
  title,
  enTitle,
  staticTypeNumber,
  data,
  percentList,
}) => {
  return (
    <PtCard
      title={title}
      entitle={enTitle}
      suffix={
        <div className="card_text">
          总接户数： <div className="text_num">64210</div>
          <div className="text_unit">户</div>
        </div>
      }
    >
      <div className={"pt-village"}>
        <div className={"pt-village__body"}>
          <div className={"pt-village__body--left"}>
            <PtSvgCircle
              percentList={[75, ...percentList]}
              svgSize={142}
              circleSizeList={[124, 106, 88, 72]}
              strokeList={[
                "rgba(50, 197, 255, 1)",
                "rgba(0, 255, 203, 1)",
                "rgba(2, 210, 129, 1)",
                "rgba(255, 201, 111, 1)",
              ]}
              strokeWidth={4}
            />
            <PtIcon className={"point-circle"} src={pointCircle} />
            <div className={"bg"} />
            <PtIcon className={"water"} src={waterImg} />
          </div>
          <div className={"pt-village__body--right"}>
            {data.map((it, i) => {
              return (
                <div key={i} className={"village-info"}>
                  <div className={`colortag${i + 1}`} />
                  <div className={"dateInfo"}>
                    <div className={"cornerLine"} />
                    <div className={"border"}>
                      <div className={"title"}>{it.title}</div>
                      <div className={"flex-center"}>
                        <div className={"number"}>{it.value}</div>
                        <div className={"unit"}>{it.unit}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </PtCard>
  );
};

export const PtCondition = ({ operaStatusList, timelyrate }) => {
  const [status, setStatus] = useState("warn");
  const lengendList = [
    { text: "告警", value: "warn", color: "#E34438" },
    { text: "处理", value: "handle", color: "#FFC96F" },
    { text: "完成", value: "complete", color: "#00FFCB" },
  ];
  const onLengendChanged = (value) => {
    setStatus(value);
  };
  
  return (
    <PtCard
      title={"运营情况1"}
      suffix={
        <div>
          <div className={"newBox"}>
            <PtSmallCircleTab
              data={lengendList}
              colorList={[
                "rgba(233, 111, 101, 1)",
                "rgba(255, 201, 111, 1)",
                "rgba(0, 255, 203, 1)",
              ]}
              onChange={onLengendChanged}
            />
          </div>
        </div>
      }
    >
      <div className={"pt-opera-status"}>
        <div className={"pt-opera-status__body"}>
          <div className={"dataArea"}>
            {operaStatusList.map((r, i) => {
              return (
                <div className={"town"} key={i}>
                  {r.data.map((d_item, d_index) => {
                    if (d_item.value == status) {
                      return (
                        <div className={"graphics"} key={d_index}>
                          <Circle
                            data={r}
                            index={i}
                            size={48}
                            r={22}
                            defaultOptions={status}
                          />
                          <PtIcon src={smallCircle} className={"img"} />
                          <div>{d_item.number}</div>
                        </div>
                      );
                    }
                  })}
                  <div className={"name"}>{r.title}</div>
                </div>
              );
            })}
          </div>
          <PtProgress text={"处置及时率"} percent={timelyrate} />
        </div>
        <div className={"pt-pipenetwork-footer"}></div>
      </div>
    </PtCard>
  );
};

export const PtOperationStatus = ({
  timelyrate,
  operaStatusList = [],
  progressbarData,
}) => {
  const tabsList = [
    { text: "运营排名", value: 0 },
    { text: "事件处置", value: 1 },
  ];
  const lengendList = [
    { text: "告警", value: "warn", color: "#E34438" },
    { text: "处理", value: "handle", color: "#FFC96F" },
    { text: "完成", value: "complete", color: "#00FFCB" },
  ];
  const [type, setType] = useState(0);
  const [status, setStatus] = useState("warn");
  const [activeButton, setActiveButton] = useState(0);
  const [isActive, setIsActive] = useState("");
  const [operaList , setOperaList] = useState([]) 
  const [operaAmount , setOperaAmount] = useState(100);

  const [rankData, setRankData] = useState([]);

  const [rankDate , setRankDate] = useState(moment().format('YYYY-MM'))

  const suffixKey = useSelector((value: any) =>
    value.getIn(["header", "suffixKey"])
  );

  const ButtonList = [
    {
      title: "每月巡检率",
      color: "#00FFCB",
      icon: "icon-paixu1",
    },
    {
      title: "事件处理率",
      color: "#00BEFF",
      icon: "icon-paixu2",
    },
  ];
  const onDateChanged = (value) => {
    console.log(value);
  };

  const onLengendChanged = (value) => {
    setStatus(value);
  };

  const typeChange = (value) => {
    setType(value);
  };

  const getRankData = () => {
    const date = new Date(rankDate);

    axios
    .get("/app/forward/static/device/event_static/village", {
      params: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        // day: date.getDate() - 1,
        area_code: MinorSkinList[suffixKey].code,
        get_all: "1",
      },
    })
    .then((res: any) => {
      if (res.code === 200 && res.data.success) {
        const list = res.data.data.map(area => ({
          title: area.area_name,
          total: area.done_num + area.wait_deal_num  + area.dealing_num, // 假设 deal_pat 代表该地区的总处理量
          data: [
              { type: "完成", value: "complete", number: area.done_num, color: "#00FFCB" },
              { type: "告警", value: "warn", number: area.wait_deal_num, color: "#E34438" },
              { type: "处理", value: "handle", number: area.dealing_num, color: "#FFC96F" }
          ]
        }));
        
        setOperaList(list);
      }

      const areas = res.data.data;

      // 初始化总数计数器
      let totalDone = 0;
      let totalDealing = 0;
      let totalWaitDeal = 0;

      // 累加所有区域的数值
      areas.forEach(area => {
          totalDone += area.done_num;
          totalDealing += area.dealing_num;
          totalWaitDeal += area.wait_deal_num;
      });

      // 计算总的处理率
      const totalTransactions = totalDone + totalWaitDeal + totalDealing;
      const completedTransactions = totalDone + totalDealing;
      let overallProcessRate = 0;

      if (totalTransactions > 0) {
          overallProcessRate = (completedTransactions / totalTransactions) * 100; // 转换为百分比
      }
      setOperaAmount(overallProcessRate)
      
      
    });

    axios
    .get("/app/forward/static/patrol/static/village", {
      params: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        area_code: MinorSkinList[suffixKey].code,
        get_all: "1",
      },
    })
    .then((res: any) => {
      console.log(res , '村庄');
      if (res.code === 200 && res.data.success) {
        setRankData(res.data.data);
      }
    });

    // axios
    // .get("/app/forward/static/device/static/village", {
    //   params: {
    //     year: date.getFullYear(),
    //     month: date.getMonth() + 1,
    //     // day: date.getDate() - 1,
    //     area_code: MinorSkinList[suffixKey].code,
    //     get_all: "1",
    //   },
    // })
    // .then((res: any) => {
    //   console.log(res);
      
    // });

    // axios
    // .get("/app/forward/static/person/static/village", {
    //   params: {
    //     year: date.getFullYear(),
    //     month: date.getMonth() + 1,
    //     // day: date.getDate() - 1,
    //     area_code: MinorSkinList[suffixKey].code,
    //     get_all: "1",
    //   },
    // })
    // .then((res: any) => {
    //   console.log(res);
      
    // });
    
    

    // console.log(date);
    
    // axios
    //   .get("/work_order/forward_app_web/basic_stat/rank/v2", {
    //     params: {
    //       year: date.getFullYear(),
    //       month: date.getMonth() + 1,
    //       // day: date.getDate() - 1,
    //       area_code: MinorSkinList[suffixKey].code,
    //       get_all: "1",
    //     },
    //   })
    //   .then((res: any) => {
    //     if (res.code === 200 && res.data.success) {
    //       setRankData(res.data.data.rank_list);
    //     }
    //   });
  };

  useEffect(() => {
    getRankData();
  }, [suffixKey , rankDate]);

  return (
    <PtCard
      title={"运营排名"}
      classN={'z-2'}
      entitle={"operation status"}
      suffix={
        <div className={"pt-opera-status__suffix"}>
          {operaList.length ? (
            <PtTab data={tabsList} value={type} onChange={typeChange} />
          ) : null}
        <div className="date_type" style={{width:'100px' , marginLeft: '10px'}}>
					<DatePicker
						allowClear={false}
            defaultValue={moment(rankDate)}
						picker='month'
						size={"small"}
						onChange={(value , dateString) => setRankDate(dateString)}
					/>
        </div>
        
          {/* <div className={"dateSelect"}>
            
            <Select
              defaultValue={"2021"}
              bordered={false}
              getPopupContainer={(triggerNode) => triggerNode}
              onChange={(value) => onDateChanged(value)}
            >
              {dateSelectList.map((r, i) => {
                return (
                  <Option key={i} value={r.year}>
                    {r.title}
                  </Option>
                );
              })}
            </Select>
          </div> */}
        </div>
      }
    >
      {type === 0 ? (
        <div className={"pt-progressbar-box"}>
          <div className={"progressbar-content"}>
            {rankData.map((item, i) => {
              return (
                <PtDoubleProgressbar
                  key={i}
                  data={item}
                  dataIndex={i}
                  isActive={i.toString() === isActive}
                  onMouseEnter={() => setIsActive(i.toString())}
                  onMouseLeave={() => setIsActive("")}
                />
              );
            })}
          </div>
          {/* <div className={"pt-progress-button-box"}>
            {ButtonList.map((item, i) => {
              return (
                <div
                  className={`${i === activeButton ? "active" : ""}`}
                  key={i}
                  style={{
                    borderColor: `${i === activeButton ? "rgba(0, 255, 203, 1)" : "#6d7278"
                      }`,
                    background: `${i === activeButton ? "rgba(0, 255, 203, 0.15)" : ""
                      }`,
                  }}
                  onClick={() => setActiveButton(i)}
                >
                  <div style={{ background: item.color }} />
                  <span>{item.title}</span>
                  <PtIcon className={item.icon} />
                </div>
              );
            })}
          </div> */}
        </div>
      ) : (
        <div className={"pt-opera-status"}>
          <div className={"pt-opera-status__body"}>
            <div className={"dataArea"}>
              {operaList.map((r, i) => {
                return (
                  <div className={"town"} key={i}>
                    {r.data.map((d_item, d_index) => {
                      if (d_item.value == status) {
                        return (
                          <div className={"graphics"} key={d_index}>
                            <Circle
                              data={r}
                              index={i}
                              size={48}
                              r={22}
                              defaultOptions={status}
                            />
                            <PtIcon src={smallCircle} className={"img"} />
                            <div>{d_item.number}</div>
                          </div>
                        );
                      }
                    })}
                    <div className={"name"}>{r.title}</div>
                  </div>
                );
              })}
            </div>
            <div className={"lengend"}>
              <PtSmallCircleTab
                data={lengendList}
                colorList={[
                  "rgba(233, 111, 101, 1)",
                  "rgba(255, 201, 111, 1)",
                  "rgba(0, 255, 203, 1)",
                ]}
                onChange={onLengendChanged}
              />
            </div>
            <PtProgress text={"处置及时率"} percent={operaAmount} />
          </div>
          <div className={"pt-pipenetwork-footer"}></div>
        </div>
      )}
    </PtCard>
  );
};

export const PtDoubleProgressbar = ({ data, onMouseEnter , onMouseLeave , isActive , dataIndex }) => {
  const list = [
    {
      title: "每月巡检率",
      value: data.patrol_deal_pat,
      color: "#00FFCB",
    },
    // {
    //   title: "事件处理率",
    //   value: data.except_deal_pat,
    //   color: "#00BEFF",
    // },
  ];
  return (
    <div
      className={`double-progressbar-style ${isActive ? "progressbar-active" : ""
        }`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
      <div className={`numbering`}>
        {dataIndex + 1}
      </div>
      <div className={"title"} title={data.area_name}>
        {data.area_name}
      </div>
      <div className={`content`}>
        {list.map((item, i) => (
          <div
            key={i}
            style={{
              width: `${item.value}%`,
              background: `${item.color}`,
            }}
          />
        ))}
      </div>
      {isActive && (
        <div className={"popup"}>
          <div>{data.area_name}</div>
          {list.map((item, i) => (
            <div key={i}>
              <div style={{ background: `${item.color}` }}></div>
              <div>{item.title}</div>
              <div>{item.value}%</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

interface CircleProps {
  data: any;
  size: number;
  r: number;
  index: number;
  defaultOptions: string;
}

export class Circle extends React.Component<CircleProps> {
  constructor(props: CircleProps) {
    super(props);
  }

  componentDidMount() {
    const { r } = this.props;
    this.init(r);
  }

  componentDidUpdate(prevProps: CircleProps) {
    // 检查半径 `r` 是否发生变化，如果是，则重新初始化
    if (prevProps.data !== this.props.data) {
      this.init(this.props.r);
    }
  }

  init = (outr) => {
    let data = this.props.data;
    let drawing: any = document.getElementById(this.props.index + "");
    let ctx = drawing.getContext("2d");
    let ox = (drawing.width - outr * 2) / 2 + outr;
    let oy = (drawing.height - outr * 2) / 2 + outr;
    let startAngle = 0.75 * 2 * Math.PI;
    let endAngle = 0.75 * 2 * Math.PI;
    ctx.clearRect(0, 0, drawing.width, drawing.height);

    for (var i = 0, len = data.data.length; i < len; i++) {
        // 忽略数量为0的数据项
        if (data.data[i].number === 0) {
            continue;
        }

        ctx.beginPath();
        let angleIncrement = (data.data[i].number / data.total) * 2 * Math.PI;
        endAngle += i === data.data.length - 1 ? angleIncrement - 0.01 * 2 * Math.PI : angleIncrement;
        ctx.lineWidth = data.data[i].value === this.props.defaultOptions ? 3 : 2;
        ctx.strokeStyle = data.data[i].color;
        ctx.arc(ox, oy, outr, startAngle, endAngle);
        ctx.stroke();
        startAngle = endAngle + 0.01 * 2 * Math.PI;
    }
  };


  render() {
    const { size } = this.props;
    return (
      <canvas
        width={size}
        height={size}
        id={this.props.index + ""}
        className={"canvas"}
      ></canvas>
    );
  }
}

export const PtRealTimeEvent = ({ scrollY = 200 }) => {
  const typeMap = {
    0: "无",
    1: "一级",
    2: "二级",
    3: "三级",
  };
  const typeColorMap = [
    { color: "rgb(109, 114, 120)", bg: "rgba(109, 114, 120, 0.15)" },
    { color: "rgba(0, 190, 255, 1)", bg: "rgba(0, 190, 255, 0.15)" },
    { color: "rgba(255, 201, 111, 1)", bg: "rgba(255, 201, 111, 0.15)" },
    { color: "rgba(224, 32, 32, 1)", bg: "rgba(224, 32, 32, 0.15)" },
  ];
  const columns = [
    {
      title: "事件名称",
      dataIndex: "title",
      width: 100,
      render: (text) => (
        <div title={text} className="table_title">
          {text}
        </div>
      ),
    },
    {
      title: "上报时间",
      dataIndex: "createtime",
      width: 110,
    },
    {
      title: "告警等级",
      dataIndex: "warning_level",
      width: 70,
      align: "center" as "left" | "center" | "right",
      render: (text, record) => (
        <Tag
          style={{
            fontSize: 14,
            borderColor: typeColorMap[text].color,
            backgroundColor: typeColorMap[text].bg,
          }}
        >
          {typeMap[text]}
        </Tag>
      ),
    },
  ];

  const tabList = [
    { value: 0, text: "全部" },
    { value: 1, text: "一级" },
    { value: 2, text: "二级" },
    { value: 3, text: "三级" },
  ];

  const [type, setType] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const suffixKey = useSelector((value: any) =>
    value.getIn(["header", "suffixKey"])
  );

  const getDataSource = () => {
    const params = {
      page: 1,
      limit: 5000,
      // zonecode: MinorSkinList[suffixKey].code,
    };
    axios
      .get("/work_order/forward_app_web/event_manage/querybypart", {
        params: type === 0 ? params : { ...params, warning_level: type },
      })
      .then((res: any) => {
        if (res.code === 200 && res.data.success) {
          const list = [];
          const data = res.data.data.data;
          
          data.forEach((item) => {
            item.warning_level_txt !== "无" && list.push(item);
          });
          
          setDataSource(list);
        }
      });
  };

  const typeChange = (value) => {
    setType(value);
  };

  useEffect(() => {
    getDataSource();
    let interval = setInterval(() => {
      getDataSource();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [type]);

  return (
    <PtCard
      title={"实时事件通报"}
      entitle={"Real-time event notification"}
      suffix={
        <div>
          <PtTab data={tabList} value={type} onChange={typeChange} />
        </div>
      }
    >
      <Table
        className={"transparent-table"}
        columns={columns}
        pagination={false}
        scroll={{ y: scrollY }}
        dataSource={dataSource}
      />
    </PtCard>
  );
};

export const PtMinorOperatingIndex = ({ data }) => {
  const [type, setType] = useState(0);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(1);
  const [active, setActive] = useState(undefined);
  const [activeIndex, setActiveIndex] = useState(undefined);
  const tabList = [
    { value: 0, text: "泵站" },
    { value: 1, text: "场站" },
    { value: 2, text: "管线" },
  ];

  useEffect(() => {
    const a = data.length;
    setTotal(Math.ceil(a / 30));
  }, []);

  const typeChange = (value) => {
    setType(value);
    let list = [];
  };
  const onPaginationChange = (key) => {
    let newCurrent = current;
    if (key === 1) {
      setCurrent(newCurrent - 1);
    } else {
      setCurrent(newCurrent + 1);
    }
  };

  const circleClick = (i, name) => {
    setActiveIndex(i);
    setActive(activeIndex !== i);
    // onClick(activeIndex !== i, name)
  };

  return (
    <PtCard
      title={"运行状态"}
      entitle={"Operating index"}
      suffix={
        <div style={{ display: "flex" }}>
          <PtPagination
            current={current}
            total={total}
            onClick={(key) => onPaginationChange(key)}
          />
          <PtTab data={tabList} value={type} onChange={typeChange} />
        </div>
      }
    >
      <div className={"pt-minor-operating"}>
        <div className={"pt-minor-operating__list"}>
          {data.map((item, i) => {
            return (
              <div
                key={i}
                className={"pt-minor-operating--content"}
                onClick={() => circleClick(i, item.name)}
              >
                <PtSvgCircle
                  click={true}
                  active={active && activeIndex === i}
                  svgSize={56}
                  circleSizeList={[48, 36, 24]}
                  percentList={item.data}
                />
                <div className={"content__text"}>{item.name}</div>
              </div>
            );
          })}
        </div>
        <div className={"pt-minor-operating__lengend"}>
          <div>水处理量</div>
          <div>巡检剩余天数</div>
        </div>
      </div>
    </PtCard>
  );
};

const PtPagination = ({ current, total, onClick }) => {
  return (
    <div className={"pt-pagination"}>
      <div
        className={`pt-pagination--left ${current === 1 && "unActive"}`}
        onClick={() => {
          if (current !== 1) {
            onClick(1);
          }
        }}
      >
        <PtIcon className={"icon-jiantou2"} />
      </div>
      <div className={"pt-pagination--content"}>{`${current}/${total}`}</div>
      <div
        className={`pt-pagination--right  ${current === total && "unActive"}`}
        onClick={() => {
          if (current !== total) {
            onClick(2);
          }
        }}
      >
        <PtIcon className={"icon-jiantou2"} />
      </div>
    </div>
  );
};



/**
 * @description             设备实时监测模块
 * @param  data             选中的设备信息
 * @param  type             设备类型(3:管线，2:泵站，1:场站)
 * @param  openKey          当前展示内容(0:报警记录,1:监测指数)
 * @param  setOpenKey       改变当前展示内容
 * @param  renewState       更新当前设备的报警记录
 */

export const PtTimeMonitoring = ({
  data,
  type,
  openKey,
  setOpenKey,
  renewState,
}) => {
  const [list, setList] = useState([]); //监测指数列表
  const [specialList, setSpecialList] = useState([]);
  const [spec, setSpec] = useState(0); //当日累计处理量
  const [dealWithKey, setDealWithKey] = useState(1); //处理量统计类型(1:周,2:月,3:年)
  const [dealWithData, setDealWithData] = useState([]); //处理量统计
  const [recordList1, setRecordList1] = useState([]); //未读列表
  const [recordList2, setRecordList2] = useState([]); //已读列表
  const [recordKey, setRecordKey] = useState(0); //报警记录展示内容
  const dealWith = [
    {
      value: 1,
      text: "周",
    },
    {
      value: 2,
      text: "月",
    },
    {
      value: 3,
      text: "年",
    },
  ];
  useEffect(() => {
    // 获取流量
    axios
      .get("/monitor_station_process/statistics", {
        params: {
          code: data.code,
        },
      })
      .then((res: any) => {
        if (res.code === 200) {
          setSpec(res.data.day.slice(0, res.data.day.indexOf(".") + 3));
        }
      });
    getDealWithList(dealWithKey);
    getRecordList();
    let webSocketUrl = `${process.env.REACT_APP_WSS_API}/msg/${data.code}`;
    let ws = new WebSocket(webSocketUrl);

    ws.onopen = (e) => {
      console.log("连接成功1");
    };

    ws.onmessage = (e) => {
      const newData = JSON.parse(e.data);
      if (type === 1) {
        if (data.yardType === 1) {
          const newArr1 = [];
          const newArr2 = [];
          newData.forEach((element, i) => {
            if (element.alongFlag) {
              newArr1.push(element);
            } else {
              newArr2.push(element);
            }
          });
          setSpecialList(newArr1);
          setList(newArr2);
        } else {
          setList(newData);
        }
      } else {
        newData.forEach((element, i) => {
          if (element.devName === "当日流量" || element.devName === "当日排污量") {
            setSpec(element.devValue);
            newData.splice(i, 1)
          }
        });
        setList(newData);
      }
    };

    return () => {
      ws.close();
      setList([]);
      setSpec(0);
    };
  }, [data.code]);

  useEffect(() => {
    if (!Boolean(openKey)) {
      getRecordList();
    }
  }, [openKey]);

  /**
   * @description     获取对应类型处理量统计值
   * @param type      1:周，2:月，3:年
   */

  const getDealWithList = (type) => {
    axios
      .get("/device_latest/week_day_month", {
        params: {
          type,
          code: data.code,
        },
      })
      .then((res) => {
        setDealWithData(res.data);
      });
  };

  /**
   * @description     获取报警记录列表
   */

  const getRecordList = () => {
    axios
      .get("/device_alarm_record/query_all", {
        params: {
          code: data.code,
          state: 0,
        },
      })
      .then((res) => {
        setRecordList1(res.data);
      });
    axios
      .get("/device_alarm_record/query_all", {
        params: {
          code: data.code,
          state: 1,
        },
      })
      .then((res) => {
        setRecordList2(res.data);
      });
  };

  /**
   * @description     改变报警记录状态
   * @param   id
   */

  const changeState = (id) => {
    axios
      .get("/device_alarm_record/read_msg", {
        params: {
          id,
        },
      })
      .then((res) => {
        message.success(res.data);
        getRecordList();
        renewState();
      });
  };

  console.log(type);
  return (
    <div className={"pt-time-monitoring"}>
      <PtContentDisplay
        title={"监测指数"}
        visible={Boolean(openKey)}
        onClick={() => {
          setOpenKey(Number(Boolean(!openKey)));
        }}
      >
        {type !== 1 && (
          <PtTableLine
            boxStyle={{
              alignItems: "center",
            }}
            leftComponent={
              <div className={"stations--left"}>
                <div className={"style3"}>{type === 14 ? "当日排污量" : "当日处理量"}</div>
              </div>
            }
            rightComponent={
              <div
                className={"pumpStation--right"}
                style={{ alignItems: "flex-end" }}
              >
                <div style={{ height: "29px", fontSize: "20px" }}>{spec}</div>
                <div className={"right__unit"}> m³</div>
              </div>
            }
            bottomComponent={
              <>
                <div
                  style={{
                    width: "100%",
                    marginBottom: "17px",
                    display: "flex",
                    justifyContent: "space-between",
                    color: "rgba(255,255,255,0.8)",
                    fontSize: "14px",
                  }}
                >
                  <span>处理量统计</span>
                  <PtTab
                    data={dealWith}
                    value={dealWithKey}
                    onChange={(value) => {
                      setDealWithKey(value);
                      getDealWithList(value);
                    }}
                  />
                </div>
                <MonitorCharts data={dealWithData} />
              </>
            }
          />
        )}

        {type === 1 && data.yardType === 1 ? (
          <>
            {specialList.map((it, i) => (
              <PtTableLine
                key={i}
                leftText={it.name}
                rightComponent={
                  <div className={"stations--right"}>
                    {it.out.value ? (
                      <>
                        <div className={"right__content"}>{it.out.value}</div>
                        <div className={"right__unit"}>{it.out.unit}</div>
                      </>
                    ) : (
                      <div>-</div>
                    )}
                  </div>
                }
              />
            ))}
            <StationsTable dataSource={list} />
          </>
        ) : (
          <>

            {list.map((_item, i) => {
              return _item.code === data.code ?
                <PtTableLine
                  key={i}
                  leftText={_item.devName}
                  rightComponent={
                    <div className={"pumpStation--right"}>
                      <>
                        <div className={"right__content"}>{_item.devValue ? _item.devValue : "-"}</div>
                        <div className={"right__unit"}>{_item.devUnit ? _item.devUnit : (_item.devName.includes("流量") ? "m³" : (_item.devName.includes("实时液位") ? "m" : _item.devUnit))}</div>
                      </>
                    </div>
                  }
                /> : <></>
            })}
          </>
          // displayList.map((_item, i) => {

          //   <PtTableLine
          //     key={i}
          //     leftText={_item.name}
          //     rightComponent={
          //       <div className={"pumpStation--right"}>
          //         <>
          //           <div className={"right__content"}>{getName(_item) ? getName(_item).devValue : "-"}</div>
          //           <div className={"right__unit"}>{getName(_item) ? getName(_item).devUnit : "-"}</div>
          //         </>
          //       </div>
          //     }
          //   />
          // })
        )}
      </PtContentDisplay>
      <PtContentDisplay
        title={"报警记录"}
        visible={!Boolean(openKey)}
        onClick={() => {
          setOpenKey(Number(Boolean(!openKey)));
        }}
      >
        <div className={"record__head"}>
          <div>
            <div
              className={`record__tag ${!Boolean(recordKey) && "record__active__tag"
                }`}
              onClick={() => {
                setRecordKey(0);
              }}
            >
              未读 ({recordList1.length})
            </div>
            <div
              className={`record__tag ${Boolean(recordKey) && "record__active__tag"
                }`}
              onClick={() => {
                setRecordKey(1);
              }}
            >
              已读 ({recordList2.length})
            </div>
          </div>
          <div></div>
        </div>
        <div className={"record__content"}>
          {!Boolean(recordKey)
            ? recordList1.map((item, i) => {
              return (
                <RecordContentBox
                  key={i}
                  data={item}
                  changeState={(value) => {
                    changeState(value);
                  }}
                />
              );
            })
            : recordList2.map((item, i) => {
              return (
                <RecordContentBox
                  key={i}
                  data={item}
                  changeState={(value) => {
                    changeState(value);
                  }}
                />
              );
            })}
        </div>
      </PtContentDisplay>
    </div>
  );
};

/**
 * @description         实时监测-统计图
 * @param data          展示数据
 */

const MonitorCharts = ({ data = [] }) => {
  const [max, setMax] = useState(0);
  useEffect(() => {
    let newMax = 0;
    Promise.all(
      data.map((it) => {
        if (it.proc > newMax) {
          newMax = it.proc;
        }
      })
    ).then((res) => {
      setMax(newMax + 20);
    });
  }, [data]);
  return (
    <Chart
      width={data.length < 7 ? "60%" : "100%"}
      height={68}
      autoFit
      data={data}
      interactions={["active-region"]}
      padding={0}
      scale={{
        proc: {
          max,
        },
      }}
      pure={true}
    >
      <Interval
        position="date*proc"
        color={"rgba(178, 179, 181, 1)"}
        size={5}
      />
      <Tooltip>
        {(title, items) => {
          return (
            <div style={{ padding: "8px 0", minWidth: "120px" }}>
              <div
                style={{
                  fontSize: "14px",
                  marginBottom: "12px",
                }}
              >
                {title}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ fontSize: "18px", width: "60px" }}>处理量</div>
                <span style={{ fontSize: "18px" }}>
                  {items[0].value}
                  <span style={{ fontSize: "12px", color: "rgba(0,0,0,0.8)" }}>
                    m³
                  </span>
                </span>
              </div>
            </div>
          );
        }}
      </Tooltip>
    </Chart>
  );
};

/**
 * @description         报警记录内容容器
 * @param data          记录内容
 * @param changeState   改变状态
 */

export const RecordContentBox = ({ data, changeState }) => {
  return (
    <div className={"record__content__box"}>
      <PtTableLine leftText={data.name} rightText={data.alarmTime} />
      <PtTableLine leftText={"内容"} rightText={data.msg} />
      <PtTableLine leftText={"报警值"} rightText={data.value} />
      {
        data.type === 2 && <PtTableLine leftText={"解除时间"} rightText={data.restoreTime} />
      }
      <PtTableLine
        leftText={"状态"}
        rightComponent={
          <div
            className={`record__state ${Boolean(data.state) ? "record--green" : "record--red"
              }`}
            onClick={() => {
              if (!Boolean(data.state)) {
                changeState(data.id);
              }
            }}
          >
            {Boolean(data.state) ? "已读" : "未读"}
          </div>
        }
      />
    </div>
  );
};

/**
 * @description         展开按钮
 * @param onClick       点击事件
 */

export const ExpandButton = ({ onClick }) => {
  return (
    <div className={`expand__button`} onClick={onClick}>
      <div>
        <PtIcon className={"icon-jiantou jiantou1"} />
        <PtIcon className={"icon-jiantou jiantou2"} />
      </div>
    </div>
  );
};

/**
 * @description         管线点击后的内容
 * @param data          选中的管线
 * @returns
 */

export const PipeLineContent = ({ data }) => {
  const [pipe, setPipe] = useState([]);
  useEffect(() => {
    axios.get(`/pipeline/queryInfoById/${data.id}`).then((res) => {
      const { data } = res;
      const {
        attributeList,
        dataList,
        lineStartList: [startLon, startLat],
        lineEndList: [endLon, endLat],
        serial,
      } = data;
      const attr = [
        {
          title: "管线代码",
          value: serial,
        },
        {
          title: "起点点号",
          value: "N/A",
        },
        {
          title: "起点坐标",
          value: `${startLon},${startLat}`,
        },
        {
          title: "终点点号",
          value: "N/A",
        },
        {
          title: "终点坐标",
          value: `${endLon},${endLat}`,
        },
      ];
      const _attributeList = attributeList.map((item) => {
        return {
          title: item.pipeAttributeName,
          value: item.pipeAttributeItemName || "-",
        };
      });
      attr.push(..._attributeList);
      const _dataList = dataList.map((item) => {
        return {
          title: item.property,
          value: item.value || "-",
        };
      });
      attr.push(..._dataList);
      setPipe([...attr]);
    });
  }, [data.id]);
  return (
    <div className={"pipeline__content"}>
      {pipe.map((item, i) => (
        <PtTableLine key={i} leftText={item.title} rightText={item.value} />
      ))}
    </div>
  );
};
