import { useState } from "react";
import { Tree } from "antd";
import { PtChildTitle, PtSearch, PtTab } from "../../../../components";
import { stateTab } from "../../../data";

const { TreeNode } = Tree;

const LeftTree = ({
  total,
  assetsSkinActiveData,
  drawerKey, // 1场站  2泵站 3负压
  getList,
  equipmentList,
  onChange,
}) => {
  const [tabKey, setTabKey] = useState(0);
  const [inputText, setInputText] = useState("");

  const [expandedKeys, setExpandedKeys] = useState([]);

  const treeSelect = (selectedKeys, e) => {
    const value = e.node.data;

    if (value) {
      console.log(value)
      onChange(value);
      if (value.position)
        if (value.position.x && value.position.y) {
          window["CesMaps"].flyTo([
            Number(value.position.x),
            Number(value.position.y),
            500,
          ]);
        }
    } else {
      const newKeys = [...expandedKeys];
      const index = newKeys.indexOf(e.node.key);

      if (index !== -1) {
        newKeys.splice(index, 1);
      } else {
        newKeys.push(e.node.key);
      }
      setExpandedKeys(newKeys);
    }
  };

  const isExit = (title: string) => {
    const list = ["东沁村", "白山村", "莆头村", "苏厝村", "石前村", "石头村"];
    if (list.includes(title))
      return true;
    else
      return false;
  }

  const getLen = (item: any) => {
    let count = 0;

    if (item.list && item.list.length) {
      count += item.list.length;
    }
    if (item.children && item.children.length) {
      item.children.forEach(child => {
        count += getLen(child);
      });
    }
    return count;
  }

  const renderTreeTitle = (item: any) => {
    let count = getLen(item);
    // item.yardTypeSate = {
    //   1:4,
    //   2:1
    // }
    
    const water = item?.yardTypeState ?  item?.yardTypeState[1] : null
    const equip = item?.yardTypeState ? item?.yardTypeState[2] : null
    console.log(water , equip);
    
    return item.children ? (
      <span style={{
        color: isExit(item.title) ? "#3fff3f" : "#fff"
      }}>{item.title}({count})</span>
    ) : (
      <div
        className={`tree_title 
         hover_${item.state === 1 ? "success" : "warning"}
      ${item.id === assetsSkinActiveData.id &&
          `active_${item.state === 1 ? "success" : "warning"}`
          }`}
      >
        <div className="item_l">{item.name}</div>
        {drawerKey === 1 ? (
					<div className="item_r">
						{
              (water || water === 0)  && (
                <div
                className={`state_box ${water === 1 ? "success" : water === 4 ? "warning" : 'error'}`}
              >
               水质 
              </div>
              )
            }
  
            {
              (equip || equip === 0)  && (
                <div
                className={`state_box ${equip === 1 ? "success" : equip === 4 ? "warning" : 'error'}`}
              >
               MBR
              </div>
              )
            }

					</div>
				) : (
					<div className="item_r">
						{item.alarmNum > 0 && <div className={"state_box error"}>报警 ({item.alarmNum})</div>}

						{tabKey === 3 ? (
							<></>
						) : (
							<div className={`state_box ${item.state === 1 ? "success" : "warning"}`}>
								{item.state === 1
									? "正常"
									: item.state === 4
									? "离线"
									: item.state === 0
									? "未安装"
									: "异常"}
							</div>
						)}
					</div>
				)}


        
      </div>
    );
  };

  const renderTreeNodes = (data, parent?) => {
    return data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            title={() => renderTreeTitle(item)}
            key={item.areaCode}
            isLeaf={false}
          >
            {renderTreeNodes(
              item.level !== 2 ? item.children : item.list,
              item
            )}
          </TreeNode>
        );
      }
      if (parent) {
        return (
          <TreeNode
            title={() => renderTreeTitle(item)}
            key={item.id}
            data-parentKey={parent.areaCode}
            data={item}
            isLeaf={true}
          />
        );
      }
    });
  };
  return (
    <>
      <PtChildTitle
        title={`设施清单（${total}）`}
        rightContent={
          <PtTab
            data={stateTab}
            value={tabKey}
            onChange={(value) => {
              setTabKey(value);
              getList(inputText, value);
            }}
          />
        }
      />
      <div className={"stations__list"}>
        <PtSearch
          onChange={(value) => {
            getList(value, tabKey);
            setInputText(value);
          }}
        />
        <div className="collapse_box customScrollBars">
          <Tree
            blockNode
            onSelect={treeSelect}
            onExpand={treeSelect}
            expandedKeys={expandedKeys}
          >
            {renderTreeNodes(equipmentList)}
          </Tree>
        </div>
      </div>
    </>
  );
};

export default LeftTree;
