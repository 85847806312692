import * as constants from "./constants";
import { fromJS } from "immutable";

const defaultState = fromJS({
  suffixKey: 0,
  villageSuffixKey: [],
  drawerKey: 0,
  moduleKey: "0",
  areaName: "",
});

const reducer = (state: any = defaultState, action) => {
  switch (action.type) {
    case constants.setSuffixKey:
      return state.set("suffixKey", action.data);
    case constants.setDrawerKey:
      return state.set("drawerKey", action.data);
    case constants.setVillageSuffixKey:
        return state.set("villageSuffixKey", action.data);
    case constants.setModuleKey:
      return state.set("moduleKey", action.data);
    case constants.setAreaName:
      return state.set("areaName", action.data);
    case constants.setMultipleData:
      return state.merge({
        suffixKey: action.data.suffixKey,
        areaName: action.data.areaName,
      });
    default:
      return state;
  }
};

export default reducer;
