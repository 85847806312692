import React, { Component } from "react";
import { PtDrawer, PtCard, PtIcon, PtTab } from "../../components";
import { connect } from "react-redux";
import { homeActionCreators } from "../../store/home";
import {
  PipelineList,
  PipelineRight,
  LeftContent,
  StationsDetails,
  PumpStationDetails,
  RightContent,
  LeftTree,
} from "./components";
import "./index.scss";
import axios from "axios";
import { PipeLineContent } from "../components/components";
import { CardTitle, rightTab1, rightTab2 } from "../data";

/**
 * @param  drawerVisible                  抽屉显示
 * @param  drawerKey                      抽屉类型(1:场站,2:泵站,3:管线,4:负压)
 * @param  assetsSkinActiveData           左侧选中的数据
 * @param  changeActive                   改变选中的数据
 */
interface Props {
  drawerVisible: boolean;
  drawerKey: number;
  assetsSkinActiveData: any;
  changeActive: (data) => void;
}

/**
 * @param expandContent                   设备详情状态   (false:隐藏,true:展开)
 * @param equipmentList                   设备列表
 * @param rightTabKey                     右侧tab当前选中的值
 * @param isClickRecord                   是否点击报警记录按钮
 * @param total                           设备总数
 */
interface State {
  expandContent: boolean;
  equipmentList: any[];
  rightTabKey: number;
  isClickRecord: boolean;
  total: number;
}

class AssetsSkin extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      expandContent: false,
      equipmentList: [],
      rightTabKey: 1,
      isClickRecord: false,
      total: 0,
    };
  }
  tabState: null;

  componentDidMount() {
    this.getEquipmentList(this.props.drawerKey);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.drawerKey !== prevProps.drawerKey) {
      this.setState({
        expandContent: false,
        rightTabKey: 1,
      });
      this.getEquipmentList(this.props.drawerKey);
      this.props.changeActive({});
    }
  }

  /**
   * @description             获取设备列表
   * @param type              设备类型(1:场站,2:泵站,4:负压)
   * @param name              设备名称
   * @param state             设备状态
   */

  getEquipmentList = (type, name?, state?) => {
    this.setState({
      equipmentList: [],
    });    

    if (type === 4) {
      axios
        .get(`/device/treeData`, {
          params: {
            name,
            type: 14,
            state: this.tabState || null,
          },
        })
        .then((res: any) => {
          if (res.code === 200) {
            this.setState({
              equipmentList: res.data.list ? res.data.list : [],
              total: res.data.count ? res.data.count : 0,
            });
          }
        });
    } else {
      axios
        .get("/device/treeData", {
          params: {
            name,
            type: type === 1 ? 7 : 6,
            state,
          },
        })
        .then((res: any) => {
          if (res.code === 200) {
            this.setState({
              equipmentList: res.data.list ? res.data.list : [],
              total: res.data.count ? res.data.count : 0,
            });
          }
        });
    }
  };

  render() {
    const { expandContent, rightTabKey, equipmentList, isClickRecord, total } =
      this.state;
    const { drawerVisible, drawerKey, assetsSkinActiveData, changeActive } =
      this.props;
    const content = {
      1: (
        <LeftTree
          total={total}
          equipmentList={equipmentList}
          assetsSkinActiveData={assetsSkinActiveData}
          drawerKey={drawerKey}
          getList={(value, state) =>{
            this.tabState = state
            this.getEquipmentList(drawerKey, value, state)
          }}
          onChange={(value) => {
            changeActive(value.id === assetsSkinActiveData.id ? {} : value);
          }}
        />
      ),
      2: (
        <LeftTree
          total={total}
          equipmentList={equipmentList}
          drawerKey={drawerKey}
          assetsSkinActiveData={assetsSkinActiveData}
          getList={(value, state) => {
            this.tabState = state
            this.getEquipmentList(drawerKey, value, state)
          }}
          onChange={(value) => {
            changeActive(value.id === assetsSkinActiveData.id ? {} : value);
          }}
        />
      ),
      3: (
        <PipelineList
          id={assetsSkinActiveData.id ? assetsSkinActiveData.id : 0}
          onChange={(value) => {
            changeActive(value.id === assetsSkinActiveData.id ? {} : value);
          }}
        />
      ),
      4: (
        <LeftTree
          total={total}
          equipmentList={equipmentList}
          drawerKey={drawerKey}
          assetsSkinActiveData={assetsSkinActiveData}
          getList={(value, state) => {
            this.tabState = state
            this.getEquipmentList(drawerKey, value, state)
          }
            
          }
          onChange={(value) => {
            changeActive(value.id === assetsSkinActiveData.id ? {} : value);
          }}
        />
        // <LeftContent
        //   total={total}
        //   equipmentList={equipmentList}
        //   assetsSkinActiveData={assetsSkinActiveData}
        //   getList={(value, state) =>
        //     this.getEquipmentList(drawerKey, value, state)
        //   }
        //   onChange={(value) => {
        //     changeActive(value.id === assetsSkinActiveData.id ? {} : value);
        //   }}
        // />
      ),
    };
    const rightContent = {
      1: (
        <RightContent
          type={1}
          isClickRecord={isClickRecord}
          data={assetsSkinActiveData}
          tabKey={rightTabKey}
          changeData={(data)=> {
            console.log(data  , 'data');
          }}
          renewState={() => {
            
            
            this.getEquipmentList(1);
            const newData = { ...assetsSkinActiveData };
            newData.alarmNum =
              newData.alarmNum !== 0 ? newData.alarmNum - 1 : 0;
            changeActive(newData);
          }}
        />
      ),
      2: (
        <RightContent
          type={2}
          isClickRecord={isClickRecord}
          data={assetsSkinActiveData}
          tabKey={rightTabKey}
          changeData={(data)=> {
            console.log(data  , 'data');
          }}
          renewState={() => {
            this.getEquipmentList(2);
            const newData = { ...assetsSkinActiveData };
            newData.alarmNum =
              newData.alarmNum !== 0 ? newData.alarmNum - 1 : 0;
            changeActive(newData);
          }}
        />
      ),
      3: <PipelineRight data={assetsSkinActiveData} tabKey={rightTabKey} />,
      4: (
        <RightContent
          type={4}
          isClickRecord={isClickRecord}
          data={assetsSkinActiveData}
          tabKey={rightTabKey}
          changeData={(data)=> {
            console.log(data  , 'data');
          }}
          renewState={() => {
            this.getEquipmentList(4);
            const newData = { ...assetsSkinActiveData };
            newData.alarmNum =
              newData.alarmNum !== 0 ? newData.alarmNum - 1 : 0;
            changeActive(newData);
          }}
        />
      ),
    };

    return (
      <>
        <PtDrawer
          visible={drawerVisible}
          left={
            <PtCard title={CardTitle[drawerKey].title}>
              {content[drawerKey]}
            </PtCard>
          }
          right={
            assetsSkinActiveData.id ? (
              <PtCard
                title={assetsSkinActiveData.title || assetsSkinActiveData.name}
                suffix={
                  drawerKey !== 3 && (
                    <div className={"right__suffix"}>
                      <PtIcon
                        className={`${assetsSkinActiveData.collect
                          ? "icon-shoucang2"
                          : "icon-shoucang1"
                          } `}
                        style={{
                          color: "#FFB412",
                        }}
                        onClick={() => { }}
                      />
                      {assetsSkinActiveData.alarmNum ? (
                        assetsSkinActiveData.alarmNum !== 0 ? (
                          <div
                            className={"right__suffix__tag"}
                            style={{
                              cursor: "pointer",
                              background: "#fe4445",
                              boxShadow:
                                "0px 0px 6px 0px rgba(255, 69, 68, 0.7)",
                              color: "#fff",
                            }}
                            onClick={() => {
                              this.setState(
                                {
                                  isClickRecord: true,
                                  rightTabKey: 1,
                                },
                                () => {
                                  this.setState({
                                    isClickRecord: false,
                                  });
                                }
                              );
                            }}
                          >
                            <PtIcon
                              className={"icon-yujingicon "}
                              style={{
                                marginRight: "4px",
                                fontSize: "14px",
                              }}
                            />
                            报警 ({assetsSkinActiveData.alarmNum})
                          </div>
                        ) : null
                      ) : null}
                      {
                        drawerKey  === 1 ? <></> : <div
                        className={`right__suffix__tag ${assetsSkinActiveData.state === 1
                          ? "normal"
                          : "abnormal"
                          }`}
                      >
                        {assetsSkinActiveData.state === 1 ? "正常" : (assetsSkinActiveData.state === 4 ? "离线" : assetsSkinActiveData.state === 0 ? "未安装" : "异常")}
                      </div>
                      }
                      
                    </div>
                  )
                }
              >
                {drawerKey !== 3 ? (
                  <>
                    <div style={{ marginBottom: "8px" }}>
                      <PtTab
                        data={drawerKey === 3 ? rightTab1 : rightTab2}
                        value={rightTabKey}
                        onChange={(value) => {
                          this.setState({
                            rightTabKey: value,
                          });
                        }}
                      />
                    </div>
                    {rightContent[drawerKey]}
                  </>
                ) : (
                  <PipeLineContent data={{ id: assetsSkinActiveData.id }} />
                )}
              </PtCard>
            ) : (
              <></>
            )
          }
        />
        {/* drawerKey !== 4 && */}
        {drawerVisible &&
          drawerKey !== 3 &&
          
          assetsSkinActiveData.id ? (
          expandContent ? (
            <div className={"expand__box"}>
              <div>
                <div
                  className={"expand__button"}
                  onClick={() =>
                    this.setState({
                      expandContent: !expandContent,
                    })
                  }
                >
                  <div>
                    <PtIcon className={"icon-jiantou jiantou1"} />
                    <PtIcon className={"icon-jiantou jiantou2"} />
                  </div>
                </div>
                <div className={"expand__content"}>
                  {drawerKey === 1 ? (
                    <StationsDetails data={assetsSkinActiveData} />
                  ) : (
                    <PumpStationDetails data={assetsSkinActiveData} />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              className={"expand__button"}
              onClick={() =>
                this.setState({
                  expandContent: !expandContent,
                })
              }
            >
              <div>
                <PtIcon className={"icon-jiantou jiantou1"} />
                <PtIcon className={"icon-jiantou jiantou2"} />
              </div>
            </div>
          )
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapState = (state) => ({
  drawerVisible: state.getIn(["home", "drawerVisible"]),
  drawerKey: state.getIn(["header", "drawerKey"]),
  assetsSkinActiveData: state.getIn(["home", "assetsSkinActiveData"]),
});

const mapDispatch = (dispatch) => ({
  changeActive(data) {
    dispatch(homeActionCreators.setAssetsSkinActiveData(data));
  },
});

export default connect(mapState, mapDispatch)(AssetsSkin);
